import React from 'react';
import { Outlet } from 'react-router-dom';
import ModalProvider from '../providers/ModalProvider';

const RootLayout = () => {
  return (
    <ModalProvider>
      <Outlet />
    </ModalProvider>
  );
};

export default RootLayout;
